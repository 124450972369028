import React from "react"
import {Helmet} from 'react-helmet'
import SEO from '../components/seo'
import Layout from "../layouts/layout-fixed"
import { graphql } from 'gatsby'

export default ({data}) =>
  <Layout>
    <Helmet htmlAttributes={{ class:'about fixed' }} />
    <SEO title="About" />
    <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
        <div style={{
            padding: '2.5rem 2rem 1rem',
            borderRadius: '3',
            maxWidth: '90%',
          }}
          className='aboutWrapper'
        >
            <h2 style={{
              display: "inline-block",
            }}
            >
                About
            </h2>
            <p style={{
              margin: '0',
            }}
            >
                Just another website from a developer who wants to experiment stuff ;-)
                <br />
                Currently experimenting <a href="https://gatsbyjs.org" >GatsbyJS</a> &amp; <a href="https://reactjs.org/" >ReactJS</a>
            </p>
            {/*
            <form action="https://formspree.io/contact@endofline.fr" method="POST">
              <input type="text" name="name" />
              <input type="email" name="_replyto" />
              <input type="submit" value="Send" />
            </form>
            */}
        </div>
    </div>
  </Layout>

export const query = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
